import React, { useEffect, useState } from 'react'
import Layout from "../components/layout"
import Seo from "../components/seo"
import constructionPlan from "../assets/construction-plan.webp";
import ContactForm from "../components/contactForm";


const IndexPage = () => {

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setIsModalOpen(true);
  }, []);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

return (
  <Layout>
    <Seo title="Selenea rezidence" />

    <section id={"priceList"} className="price-list-section">
      <h1 className={"title-section center"}>Ponuka bytov</h1>
      <p className={"project-phases__text"}>Samotný projekt pozostáva z <b>232 bytových jednotiek</b>, ponúka štedré metráže základných <b>garzóniek</b>, štartovacie <b>2 izbové byty</b>, <b>3 izbové</b> byty pre rodiny s deťmi, či vysoko luxusné <b>4 izbové byty s terasami</b>.</p>
      <img
          loading={"lazy"}
          src={constructionPlan}
          width={1440}
          height={1018}
          alt={"Bloky výstavby"}
      />
    </section>
    <ContactForm />
  </Layout>
)
  }

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Selenea rezidence" />

export default IndexPage
