import React, { useState } from "react"
import { useForm } from "react-hook-form"
import axios from "axios"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Dominika from  "../assets/people/dominika_svecova.webp"
import Nina from  "../assets/people/nina_sebikova.webp"

const ContactForm = () => {
  const [gdpr, setGdpr] = useState(true)
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      name: "",
      phone: "",
      mail: "",
      oneRoom: false,
      twoRoom: false,
      threeRoom: false,
      fourRoom: false,
      renovation: false,
      polyfunction: false,
    },
  })
  const onSubmit = data => {
    const {
      name,
      phone,
      mail,
      oneRoom: oneRoomBoolean,
      twoRoom: twoRoomBoolean,
      threeRoom: threeRoomBoolean,
      fourRoom: fourRoomBoolean,
      renovation,
      polyfunction,
    } = data
    // Converting checkbox radio data from string "on"/"off" to true/false
    const oneRoom = !!oneRoomBoolean
    const twoRoom = !!twoRoomBoolean
    const threeRoom = !!threeRoomBoolean
    const fourRoom = !!fourRoomBoolean
    const payload = {
      name: name,
      phone: phone,
      mail: mail,
      oneRoom: oneRoom,
      twoRoom: twoRoom,
      threeRoom: threeRoom,
      fourRoom: fourRoom,
      renovation: renovation,
      polyfunction: polyfunction,
    }
    axios
      .post(
        "https://ze81u3ir74.execute-api.eu-central-1.amazonaws.com/shared/contactForm/send",
        payload
      )
      .then(res => {
        // console.log(res)
        // console.log(res.data)
        if (res.data === "success") {
          toast.success("Vaše údaje boli úspešne odoslané", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
    reset()
  }
  return (
    <section className={"contact-form-wrapper"} id={"kontakt"}>
      {/*<p class="section-separator section-separator--left">Kontakt</p>*/}
      <div className={"project-info"}>
        <h2 className={"title-section"}>
          <span>Ozvite sa nám</span> kedykoľvek
        </h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <input
            name="name"
            type="text"
            placeholder={"Vaše meno"}
            {...register("name", { required: true })}
          />
          {errors.name && (
            <p className={"invalid"}>Zadejte prosím Vaše celé jméno.</p>
          )}
          <input
            name={"phone"}
            type={"number"}
            placeholder={"Telefón"}
            {...register("phone", {
              required: true,
              minLength: 9,
            })}
          />
          {errors.phone && (
            <p className={"invalid"}>Telefonní číslo není platné.</p>
          )}
          <input
            name={"mail"}
            type={"email"}
            placeholder={"Email"}
            {...register("mail", {
              required: true,
              pattern:
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g,
            })}
          />
          {errors.mail && <p className={"invalid"}>Email není platný.</p>}
          <div className={"realestate-types"}>
            <div className={"title"}>Mám záujem o:</div>
            <div className={"room-wrapper"}>
              <div className="room">
                <input
                  type="radio"
                  name={"one-room"}
                  id={"one-room"}
                  {...register("oneRoom")}
                  onChange={() => {
                    setValue("twoRoom", false)
                    setValue("threeRoom", false)
                    setValue("fourRoom", false)
                  }}
                />
                <label htmlFor={"one-room"}>1 izbový</label>
              </div>
              <div className="room">
                <input
                  type="radio"
                  name={"two-room"}
                  id={"two-room"}
                  {...register("twoRoom")}
                  onChange={() => {
                    setValue("oneRoom", false)
                    setValue("threeRoom", false)
                    setValue("fourRoom", false)
                  }}
                />
                <label htmlFor={"two-room"}>2 izbový</label>
              </div>
              <div className="room">
                <input
                  type="radio"
                  name={"three-room"}
                  id={"three-room"}
                  {...register("threeRoom")}
                  onChange={() => {
                    setValue("oneRoom", false)
                    setValue("twoRoom", false)
                    setValue("fourRoom", false)
                  }}
                />
                <label htmlFor={"three-room"}>3 izbový</label>
              </div>
              <div className="room">
                <input
                  type="radio"
                  name={"four-room"}
                  id={"four-room"}
                  {...register("fourRoom")}
                  onChange={() => {
                    setValue("oneRoom", false)
                    setValue("twoRoom", false)
                    setValue("threeRoom", false)
                  }}
                />
                <label htmlFor={"four-room"}>4 izbový</label>
              </div>
              {/*<div className="room">*/}
              {/*  <input*/}
              {/*    type="checkbox"*/}
              {/*    name={"renovation"}*/}
              {/*    id={"renovation"}*/}
              {/*    {...register("renovation")}*/}
              {/*  />*/}
              {/*  <label htmlFor={"renovation"}>renovácia</label>*/}
              {/*</div>*/}
              <div className="room">
                <input
                  type="checkbox"
                  name={"polyfunction"}
                  id={"polyfunction"}
                  {...register("polyfunction")}
                />
                <label htmlFor={"polyfunction"}>polyfunkcia</label>
              </div>
            </div>
          </div>
          <div className={"gdpr-wrapper"}>
            <div className={"gdpr"}>
              <input
                type="checkbox"
                name={"gdpr"}
                id={"gdpr"}
                checked={gdpr}
                onChange={event => setGdpr(event.target.checked)}
                required
              />
              <label htmlFor={"gdpr"}>
                Súhlasím so zásadami ochrany osobných údajov a ich spracovaním s
                cieľom kontaktu.
              </label>
            </div>
            <button className={"btn btn--secondary"} type={"submit"}>
              <span>Odoslať</span>
            </button>
          </div>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </form>
      </div>
      {/*<div>
        <div>
          <p className={"project-contact"}>
            <b>Predajca projektu: </b>
          </p>
          <div className={"contact__info contact__info--address"}>
            <address>
              <span className="font-bold">Nitrianska realitná kancelária s.r.o.</span> <br />
                Riečna 1 <br />
                949 01 Nitra
            </address>
          </div>
        </div>
            <div className={"contact-person "}>
              <div className={"contact__info contact__info--phone"}>
                <div className={"contact-person__contact"}>
                  <p className={"contact-person__name font-bold"}>Mgr. Dominika Švecová</p>
                  <a href="tel:+421902256848" className={"link contact-person__phone"}>0902 256 848</a>
                </div>
              </div>
              <img src={Dominika} className={"contact-person__img"} alt="Dominika Švecová" width="100" height="100" />
            </div>
            <div className={"contact-person"}>
              <div className={"contact__info contact__info--phone"}>
                <div className={"contact-person__contact"}>
                  <p className={"contact-person__name font-bold"}>Mgr. Nina Šebíková</p>
                  <a href="tel:+421917854339" className={"link contact-person__phone"}>0917 854 339</a>
                </div>
                </div>
              <img src={Nina} className={"contact-person__img"} alt="Nina Šebíková"  width="100" height="100"/>
            </div>
        </div>*/}
    </section>
  )
}

export default ContactForm
